import { createHttpEndpoint } from '../../utils'

/**
 * Search configuration
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/search-api/definition#tag/search/operation/get-search-v2-configuration-scope-id}
 */
export const getConfigurationByScopeId =
  createHttpEndpoint<SearchConfiguration>({
    method: 'GET',
    operationId: 'searchConfiguration',
    path: '/bm/search/v2/configuration/:scope/:id',
  })

/**
 * Search configuration
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/search-api/definition#tag/search/operation/get-search-v2-configuration-scope}
 */
export const getConfigurationByScope = createHttpEndpoint<SearchConfiguration>({
  method: 'GET',
  operationId: 'searchConfiguration',
  path: '/bm/search/v2/configuration/:scope',
})

export type IndexSearch = {
  name: string
  title: string
  queryParams: Record<string, string | boolean | number>
}

export type Facet = {
  name: string
  title: string
  type: 'radio' | 'checkbox'
  isSortedByBusiness?: boolean
}

export type Scale = {
  maxValue: number
  facetName: string
}

export type PriceFacet = {
  name: string
  title: string
  price_facet_type: string
  scales: Scale[]
}

export type SearchConfiguration = {
  complexFilter?: string
  facets: Facet[]
  indexType: string
  indexes: { active: IndexSearch; other?: IndexSearch[] }
  priceFacet?: PriceFacet
  productGroupingType?: 'variant' | 'parent'
  isComparisonEnabled?: boolean
}
