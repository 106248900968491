import { useRuntimeConfig } from '#imports'
import { type Ref, ref, watch } from 'vue'

import { createInMemoryCache } from '@algolia/cache-in-memory'
import type { LinkExternal, LinkInternal } from '@backmarket/http-api'
import type {
  AlgoliaLink,
  AlgoliaProduct,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchAlgolia'
import {
  type SearchConfiguration,
  getConfigurationByScope,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchConfiguration'
import {
  type SearchApiKey,
  getApiKey,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchKey'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useDebounceFn } from '@vueuse/core'
import algoliasearch, { type SearchIndex } from 'algoliasearch'

import {
  algoliaHitToProduct,
  algoliaHitToVariant,
} from '../algolia/algoliaFunctions'

type Scope =
  | 'search-bar'
  | 'landing-page'
  | 'search-landing'
  | 'merchant-landing'

export interface Link {
  highlightedTitle?: string | undefined
  title: string
  link: LinkInternal | LinkExternal
  id: string
}

type AlgoliaHit<T extends Scope> = T extends 'search-bar'
  ? AlgoliaLink
  : AlgoliaProduct
type Hit<T extends AlgoliaLink | AlgoliaProduct> = T extends AlgoliaLink
  ? Link
  : Product

function isAlgoliaProduct(
  hit: AlgoliaLink | AlgoliaProduct,
): hit is AlgoliaProduct {
  return (hit as AlgoliaProduct).variant_fields !== undefined
}

function algoliaHitToLink(hit: AlgoliaLink): Link {
  return {
    // eslint-disable-next-line no-underscore-dangle
    highlightedTitle: hit._highlightResult?.title.value,
    title: hit.title,
    link: hit.link,
    id: hit.objectID,
  }
}

function transform<T extends AlgoliaLink | AlgoliaProduct>(
  hit: T,
  type?: 'parent' | 'variant',
) {
  if (isAlgoliaProduct(hit)) {
    if (type === 'parent') {
      return algoliaHitToProduct(hit)
    }
    if (type === 'variant') {
      return algoliaHitToVariant(hit)
    }
  } else {
    return algoliaHitToLink(hit)
  }

  return undefined
}

export function useAlgoliaSearch<T extends Scope>(
  scope: T,
  options = { debounce: false, debounceTime: 0 },
) {
  const query = ref<string | undefined>()
  const hits: Ref<Hit<AlgoliaHit<T>>[]> = ref([])
  const isLoading = ref(false)
  const error = ref<Error | null>(null)
  const algoliaIndex = ref<SearchIndex | null>(null)
  const queryID = ref<string | undefined>()
  const index = ref<string | undefined>()

  const configuration = ref<SearchConfiguration | null>(null)
  const apiKey = ref<SearchApiKey | null>(null)

  const { ALGOLIA_ID } = useRuntimeConfig().public

  const fetchSearchConfiguration = async () => {
    const { data, error: err } = await useHttpFetch(getConfigurationByScope, {
      pathParams: { scope },
    })
    configuration.value = data.value
    index.value = configuration.value?.indexes.active.name
    error.value = err.value
  }

  const fetchSearchApiKey = async () => {
    if (!configuration.value) {
      error.value = new Error('Missing search configuration')

      return
    }
    const { data, error: err } = await useHttpFetch(getApiKey, {
      pathParams: { index: configuration.value.indexType },
    })
    apiKey.value = data.value
    error.value = err.value
  }

  const initAlgoliaIndex = () => {
    if (configuration.value && apiKey.value) {
      algoliaIndex.value = algoliasearch(ALGOLIA_ID, apiKey.value.apiKey, {
        requestsCache: createInMemoryCache({ serializable: false }),
        responsesCache: createInMemoryCache(),
      }).initIndex(configuration.value.indexes.active.name)
    } else {
      error.value = new Error('Configuration or API key is missing!')
    }
  }

  async function search() {
    if (!algoliaIndex.value || !query.value) return

    isLoading.value = true
    try {
      const searchResult = await algoliaIndex.value.search<AlgoliaHit<T>>(
        query.value,
        {
          highlightPreTag: '<span class="body-1">',
          highlightPostTag: '</span>',
        },
      )

      queryID.value = searchResult.queryID
      hits.value = searchResult.hits
        .map((hit) => transform(hit))
        .filter((item): item is Hit<AlgoliaHit<T>> => item !== undefined)
    } catch (algoliaError) {
      error.value = new Error('Error while fetching Algolia', {
        cause: algoliaError,
      })
    } finally {
      isLoading.value = false
    }
  }

  const performSearch = options.debounce
    ? useDebounceFn(search, options.debounceTime)
    : search

  watch(query, async () => {
    if (!algoliaIndex.value) {
      initAlgoliaIndex()
    }

    if (query.value === '') {
      hits.value = []
    }
    if (query.value !== undefined) {
      await performSearch()
    }
  })

  return {
    query,
    queryID,
    hits,
    index,
    isLoading,
    error,
    configuration,
    apiKey,
    fetchSearchApiKey,
    fetchSearchConfiguration,
  }
}
