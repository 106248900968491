import { createHttpEndpoint } from '../../utils'

/**
 * Search Api Key
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/search-api/definition#tag/search/operation/get-search-api-keys-index-2023-05-22}
 */
export const getApiKey = createHttpEndpoint<SearchApiKey>({
  method: 'GET',
  operationId: 'searchGetApiKey',
  path: '/bm/search/api-keys/:index',
})

export type SearchApiKey = {
  indexType: string
  apiKey: string
}
