<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div>
    <div>
      <input
        v-model="query"
        :disabled="error !== null"
        placeholder="Search..."
        type="text"
      />
      <section>
        <h2>{{ query }} - {{ queryID }}</h2>
        <ul v-if="suggestions?.length && !isLoading">
          <li v-for="(suggestion, index) in suggestions" :key="index">
            <!-- eslint-disable vue/no-v-html -->
            <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
            <span
              class="body-1-bold"
              v-html="suggestion.highlightedTitle"
            ></span>
            <span>{{ suggestion.link }}</span>
          </li>
        </ul>
      </section>
      <div v-if="isLoading">Loading suggestions...</div>
      <div v-if="error">Error: {{ error.message }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAlgoliaSearch } from '~/scopes/search/composables/useSearch'

const {
  isLoading,
  error,
  hits: suggestions,
  query,
  queryID,
  fetchSearchConfiguration,
  fetchSearchApiKey,
} = useAlgoliaSearch('search-bar')
await fetchSearchConfiguration()
await fetchSearchApiKey()
</script>
